
import { Link } from 'react-router-dom';
import { checkAuth } from './utils/check-auth';
import LogoutButton from './components/logout-button';
import UserModel from '@/models/user';
import { useEffect, useState } from 'react';

function Header() {
    // define a user object

    const [user, setUser] = useState<UserModel | null>(null);


    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setUser(UserModel.fromJson(JSON.parse(user)));
        }
    }, []);

    return (
        <header className="flex flex-row justify-end items-right px-6 py-4">
            <div>
                {checkAuth() ? (
                    <div className="flex flex-row items-center">
                        <div className="bg-gray-300 w-10 h-10 rounded-full mr-2 ">
                            <img src={user?.cover} alt="user" className="w-full h-full object-cover rounded-full" />
                        </div>
                        <LogoutButton />
                    </div>
                ) : (
                    <Link
                        to="/login"
                        className="bg-main-green text-white font-bold py-2 px-4 rounded hover:bg-main-green-dark transition"
                    >
                        Login
                    </Link>
                )
                }
            </div>
        </header>
    );
}

export default Header;
