class RolesModel {
    id: string;
    role_id: string;
    project_id: string;

    constructor(
        id: string,
        roleId: string,
        projectId: string
    ) {
        this.id = id;
        this.role_id = roleId;
        this.project_id = projectId;
    }

    // from json
    static fromJson(json: any): RolesModel {
        return new RolesModel(
            json.id,
            json.roleId,
            json.projectId
        );
    }

    // to json
    toJson(): any {
        return {
            id: this.id,
            roleId: this.role_id,
            projectId: this.project_id
        }
    }
}

export default RolesModel;