import UserModel from "@/models/user"

// if there is a token in the local storage, it will return true, otherwise false
export const checkAuth = (): boolean => {
    const user = localStorage.getItem("user")
    if (user) {
        const userModel = UserModel.fromJson(JSON.parse(user));
        if (userModel.bearer !== "")
            return true
    }
    return false
}

