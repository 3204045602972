import { Button } from "@/components/ui/button"

import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useState } from "react"
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate"

import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"


import Project from "@/pages/home/models/project"
import { Textarea } from "@/components/ui/textarea"
import axios from "axios"
import { serverUrl } from "@/config/config"
import UserModel from "@/models/user"





export function AddTreeHoleDetection(
    {
        project,
    }:
        {
            project: Project
        }
) {
    const [isOpen, setIsOpen] = useState(false);
    const [callState, setCallState] = useState<CallState>(new InitialState());


    // selectable data
    const [date, setDate] = useState<Date>();
    const [description, setDescription] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const [csv, setCsv] = useState<File | null>(null);

    async function handleSubmit() {

        if (callState instanceof LoadingState) return;

        // check if date is not null
        if (!date) {
            setCallState(new ErrorState("Date is required"));
            return;
        }
        if (!image) {
            setCallState(new ErrorState("Image is required"));
            return;
        }
        if (!csv) {
            setCallState(new ErrorState("CSV is required"));
            return;
        }

        //         curl -X 'POST' \
        //   'http://localhost:8000/api/project/1/tree-hole-detection?date=2024-08-14%2021%3A00%3A00.000000&description=sdsad' \
        //   -H 'accept: application/json' \
        //   -H 'Content-Type: multipart/form-data' \
        //   -F 'image=@output.png;type=image/png' \
        //   -F 'csv=@predictions_Untitled.csv;type=text/csv'
        const formData = new FormData();
        formData.append("image", image);
        formData.append("csv", csv);

        setCallState(new LoadingState());

        const user = localStorage.getItem('user');
        if (!user)
            return;
        const userModel = UserModel.fromJson(JSON.parse(user));
        const token = userModel.bearer;
        // format date like this date=2024-08-14%2021%3A00%3A00.000000
        const dateString = format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");
        await axios.post(`${serverUrl}/project/${project.project_id}/tree-hole-detection?date=${dateString}&description=${description}`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "multipart/form-data"
            }
        }).then(() => {
            alert("Detection saved successfully!");
            setCallState(new InitialState());
            // close 
            setIsOpen(false);
            // refresh page
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setCallState(new ErrorState("An error occurred when saving the audit action!"));
            return;
        });

    }
    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImage(file);
        }
    };

    const handleCsvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCsv(file);
        }
    };


    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    Add detection
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle> Add Tree Hole Detection</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {ProjectName()}
                        {ActionDate()}
                        {Biodiversity_notes()}
                        {AddPhoto()}
                        {AddCsv()}

                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SaveButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function AddCsv() {
        return <div>
            <div className="flex items-top gap-2">
                <label htmlFor="csv" className="w-[110px] flex-none font-semibold pt-1">
                    CSV*
                </label>
                <div className="flex flex-col gap-2">
                    <div>
                        <input
                            id="csv"
                            type="file"
                            className="hidden"
                            accept=".csv"
                            onChange={handleCsvChange}
                        />
                        <label
                            htmlFor="csv"
                            className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer"
                        >
                            Browse
                        </label>
                    </div>
                </div>
                {/* show the name of csv file */}
                {csv && (
                    <div className="flex items-center gap-2">

                        <div className="flex items-center gap-2">
                            <span>{csv.name}</span>
                            <button
                                onClick={() => {
                                    setCsv(null);
                                }}
                                className="hover:bg-red-100 text-main-green hover:text-red-600 w-6 h-6 rounded-full"
                            >
                                X
                            </button>
                        </div>
                    </div>
                )}

            </div>
        </div>
    }

    function AddPhoto() {
        return <div>
            <div className="flex items-top gap-2">
                <label htmlFor="image" className="w-[110px] flex-none font-semibold pt-1">
                    Image*
                </label>
                <div className="flex flex-col gap-2">
                    <div>
                        <input
                            id="image"
                            type="file"
                            className="hidden"
                            onChange={handlePhotoChange}
                            accept="image/*"
                        />
                        <label
                            htmlFor="image"
                            className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer"
                        >
                            Browse
                        </label>
                    </div>
                </div> {/* show the image name with a x button */}
                {image && (
                    <div className="flex items-center gap-2">
                        <span>{image.name}</span>
                        <button
                            onClick={() => {
                                setImage(null);
                            }}
                            className="hover:bg-red-100 text-main-green hover:text-red-600 w-6 h-6 rounded-full"
                        >
                            X
                        </button>
                    </div>
                )}
            </div>
        </div>
    }


    function Biodiversity_notes() {
        return <div className="flex items-start gap-2 content-center">
            <Label htmlFor="biodiversity_notes" className="w-[110px] flex-none font-semibold pt-1">
                Notes
            </Label>

            <Textarea value={description} placeholder="Fill" className="min-h-[100px]" onChange={
                (e) => {
                    setDescription(e.target.value);
                }
            } />
        </div>
    }




    function ActionDate() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="date" className="w-[110px] flex-none font-semibold">
                    Date*
                </Label>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant={"outline"}
                            className={cn(
                                "justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {date ? format(date, "PPP") : <span>Pick a date</span>}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={date}
                            onSelect={setDate}
                            initialFocus
                        />
                    </PopoverContent>
                </Popover>

            </div>
        )

    }

    function ProjectName() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Project
            </Label>
            <Input id="partner_name" className="w-full border-none" value={project.project_name} disabled={true} />
        </div>
    }

    function SaveButton() {
        return (
            <>
                {
                    callState instanceof ErrorState &&
                    <div className="text-red-500">{callState.errorMessage}</div>
                }
                {/* if call state is loadingstate then show a circular indicator, else show save button */}
                {
                    callState instanceof LoadingState ?
                        <Button className="w-[100px] h-[40px] flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-25"
                                    fill="currentColor"
                                    d="M12 6a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
                                ></path>
                            </svg>
                            Saving
                        </Button>
                        :
                        <Button type="submit" className="w-[144px]" onClick={handleSubmit}>Save</Button>
                }
            </>
        )
    }

}



