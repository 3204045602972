import { useEffect, useState } from "react";
import Project from "./models/project"
import axios from "axios";
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate";
import { ProjectsTable } from "./components/projects-table";
import HomeStats from "./models/home-stats";
import { InfoCard } from "./components/info-card";
import { serverUrl } from "@/config/config";
import { EditSpeciesInfo } from "./components/species/edit-species-info";
import Header from "../header/header";
import { checkAuth } from "../header/utils/check-auth";
import UserModel from "@/models/user";

export default function Homepage() {

    const [projects, setProjects] = useState<Project[]>([]);
    const [callState, setCallState] = useState<CallState>(new InitialState());
    const [stats, setStats] = useState<HomeStats>(HomeStats.empty());

    // fetch projects
    useEffect(() => {
        console.log("fetching projects");
        setCallState(new LoadingState());
        const user = localStorage.getItem('user');
        if (!user)
            return;
        const userModel = UserModel.fromJson(JSON.parse(user));
        const token = userModel.bearer;
        console.log(token);
        axios.get(`${serverUrl}/project`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            setProjects(response.data.map((project: any) => Project.fromJson(project)));
            setCallState(new InitialState());
        }).catch((error) => {
            console.log(error);
            setCallState(new ErrorState(
                error.response?.data.detail || "An error occurred while fetching projects"
            ));
        });

    }, []);

    // fetch stats
    useEffect(() => {
        axios.get(`${serverUrl}/project/stats`).then((response) => {
            setStats(HomeStats.fromJson(response.data))
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <div>

            <Header />
            <div className="text-left">
                <div className="text-neutral-900 text-lg font-semibold pb-2">Projects</div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-3">
                    <InfoCard color="bg-blue-700" icon="icon-bar-chart-2.svg" title="projects" value={stats.numberOfProjects.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} />
                    <InfoCard color="bg-amber-300" icon="icon-file.svg" title="planted saplings" value={stats.numberOfSaplings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} />
                    <InfoCard color="bg-violet-700" icon="icon-mail.svg" title="maintainance actions" value={stats.numberOfActions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} />
                    <InfoCard color="bg-green-400" icon="icon-feather.svg" title="planted surface" value={stats.area.toString() + " ha"} />
                </div>
                {
                    checkAuth() && callState instanceof LoadingState ? <div>Loading...</div> : null
                }
                {
                    checkAuth() && callState instanceof ErrorState ? <div>{callState.errorMessage}</div> : null
                }
                {
                    checkAuth() ?
                        <ProjectsTable data={projects} /> : null
                }
                <div className="pt-5"></div>
                {
                    checkAuth() ? <EditSpeciesInfo /> : null
                }

            </div>
        </div>
    )
}
