import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect, useState, ChangeEvent } from "react"
import axios from "axios"
import Country from "@/pages/home/models/country"
import County from "@/pages/home/models/county"
import City from "@/pages/home/models/city"
import Partner from "@/pages/home/models/partner"
import { CallState, ErrorState, InitialState, LoadingState, SuccessState } from "@/models/callstate"
import { serverUrl } from "@/config/config"
import UserModel from "@/models/user"

type Base64File = string | null;

export function AddProject() {
    const [isOpen, setIsOpen] = useState(false);
    const [callState, setCallState] = useState<CallState>(new InitialState());

    // data from the API
    const [countries, setCountries] = useState<Country[]>([]);
    const [counties, setCounties] = useState<County[]>([]);
    const [cities, setCities] = useState<City[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);

    // selected values
    const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(null);
    const [selectedCountryId, setSelectedCountryId] = useState<string | null>(null);
    const [selectedCountyId, setSelectedCountyId] = useState<string | null>(null);
    const [selectedCityId, setSelectedCityId] = useState<string | null>(null);
    const [soilType, setSoilType] = useState<string | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [co2, setCo2] = useState<string | null>(null);
    const [warningMoistureLevel, setWarningMoistureLevel] = useState<string | null>("30");
    const [criticalMoistureLevel, setCriticalMoistureLevel] = useState<string | null>("23");
    const [socialGoal, setSocialGoal] = useState<string | null>(null);
    const [pedoReportFile, setPedoReportFile] = useState<Base64File>(null);
    const [pedoReportFilename, setPedoReportFilename] = useState<string>("");
    const [areaCoordsFile, setAreaCoordsFile] = useState<Base64File>(null);
    const [areaCoordsFilename, setAreaCoordsFilename] = useState<string>("");
    const [legalDocs, setLegalDocs] = useState<Base64File[]>([null]);
    const [legalDocsTags, setLegalDocsTags] = useState<string[]>([""]);
    const [legalDocsFilenames, setLegalDocsFilenames] = useState<string[]>([""]);

    function handlePedoReportFileChange(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0]; // Obține primul fișier selectat
        if (!file) return;

        setPedoReportFilename(file.name);

        const reader = new FileReader(); // Inițializează un FileReader

        reader.onloadend = () => {
            // La finalizarea citirii, setează valoarea Base64 în starea componentei
            setPedoReportFile(reader.result as Base64File);
        };

        // Citește fișierul ca și conținut în format Base64
        reader.readAsDataURL(file);
    }

    function handleAreaCoordsFileChange(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0]; // Obține primul fișier selectat
        if (!file) return;
        setAreaCoordsFilename(file.name);

        const reader = new FileReader(); // Inițializează un FileReader

        reader.onloadend = () => {
            // La finalizarea citirii, setează valoarea Base64 în starea componentei
            setAreaCoordsFile(reader.result as Base64File);
        };

        // Citește fișierul ca și conținut în format Base64
        reader.readAsDataURL(file);
    }

    useEffect(() => {
        if (isOpen) {
            axios.get("https://www.crm.cri.org.ro/androiddev_beta/2odoXRwPDMa/countries").then((response) => {
                setCountries(Country.fromJsonArray(response.data));
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            axios.get("https://www.crm.cri.org.ro/androiddev_beta/2odoXRwPDMa/get_partners").then((response) => {
                setPartners(Partner.fromJsonArray(response.data));
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (selectedCountryId == "176") {
            axios.get(`https://www.crm.cri.org.ro/androiddev_beta/2odoXRwPDMa/counties`).then((response) => {
                setCounties(County.fromJsonArray(response.data));
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [selectedCountryId]);

    useEffect(() => {
        if (selectedCountyId) {
            axios.get(`https://www.crm.cri.org.ro/androiddev_beta/2odoXRwPDMa/cities/${selectedCountyId}`).then((response) => {
                setCities(City.fromJsonArray(response.data));
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [selectedCountyId]);

    async function handleSubmit() {

        if (callState instanceof LoadingState) return;
        // check if all legal docs have proper tags
        for (let i = 0; i < legalDocsTags.length; i++) {
            if (legalDocs[i] != null && legalDocsTags[i] == "") {
                alert("Please select a tag for all legal documents");
                return;
            }
        }

        if (
            selectedPartnerId &&
            selectedCountryId &&
            (selectedCountyId || selectedCountryId != "176") &&
            (selectedCityId || selectedCountryId != "176") &&
            soilType &&
            type &&
            co2 &&
            socialGoal &&
            areaCoordsFile
        ) {
            setCallState(new LoadingState());

            // parse the area coords file (kml file), extract the coordinates and send them to the backend
            // areaCoordsFile is Base64 encoded, so we need to decode it first

            const kmlFile = atob(areaCoordsFile?.split(",")[1] ?? "");
            const parser = new DOMParser();
            const kmlDoc = parser.parseFromString(kmlFile, "text/xml");
            const coordinates = kmlDoc.querySelectorAll("coordinates");

            const processCoordinatePair = (pair: string): string => {
                const [longitudeStr, latitudeStr] = pair.split(",").slice(0, 2); // Extract longitude and latitude as strings
                const longitude = parseFloat(longitudeStr); // Parse longitude as float
                const latitude = parseFloat(latitudeStr); // Parse latitude as float
                const flippedCoordinates = `${latitude},${longitude}`; // Swap the coordinates and convert back to string
                return flippedCoordinates;
            };
            let coordonates = [];

            for (let i = 0; i < coordinates.length; i++) {
                let areaCoordsPair = coordinates[i].textContent?.split(" ") ?? [];
                const processedCoordinates = areaCoordsPair.map(processCoordinatePair);
                processedCoordinates.pop();
                const processedCoordinatesStr = processedCoordinates.join(" ");
                coordonates.push(processedCoordinatesStr);
            }


            let pedoReportUrl = "";
            let areaCoordsUrl = "";
            let legalDocsUrls: string[] = [];

            if (pedoReportFile) {
                const formData = new FormData();
                formData.append("path", "test/path");
                formData.append("img", pedoReportFile?.split(",")[1] ?? "");
                await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                    pedoReportUrl = response.data.url;
                }).catch((error) => {
                    console.error(error);
                    setCallState(new ErrorState("An error occurred when uploading the pedo report"));
                    return;
                });
            }

            if (areaCoordsFile) {
                const formData = new FormData();
                formData.append("path", "test/path");
                formData.append("img", areaCoordsFile?.split(",")[1] ?? "");
                await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                    areaCoordsUrl = response.data.url;
                }).catch((error) => {
                    console.error(error);
                    setCallState(new ErrorState("An error occurred when uploading the area coords"));
                    return;
                });
            }

            if (legalDocs.length > 0) {
                for (let i = 0; i < legalDocs.length; i++) {
                    if (legalDocs[i]) {
                        const formData = new FormData();
                        formData.append("path", "test/path");
                        formData.append("img", legalDocs[i]?.split(",")[1] ?? "");
                        await axios.post("https://cdn.ecotrack.ro/api.php?api=dd283cb0e738ae", formData).then((response) => {
                            legalDocsUrls[i] = response.data.url;
                        }).catch((error) => {
                            console.error(error);
                            setCallState(new ErrorState("An error occurred when uploading the legal document"));
                            return;
                        });
                    }
                }
            }

            let legal_docs = [];
            for (let i = 0; i < legalDocsUrls.length; i++) {
                if (legalDocsUrls[i]) {
                    legal_docs.push({
                        file: legalDocsUrls[i],
                        tag: legalDocsTags[i],
                    });
                }
            }


            console.log({
                partner_id: selectedPartnerId,
                partner_name: partners.find((partner) => partner.id == selectedPartnerId)?.name,
                country_id: selectedCountryId,
                country_name: countries.find((country) => country.id == selectedCountryId)?.nicename,
                county_id: selectedCountyId,
                county_name: counties.find((county) => county.id == selectedCountyId)?.name,
                city_id: selectedCityId,
                city_name: cities.find((city) => city.id == selectedCityId)?.name,
                soil_type: soilType,
                type: type,
                co2: co2,
                warning_moisture_level: warningMoistureLevel,
                critical_moisture_level: criticalMoistureLevel,
                social_goal: socialGoal,
                pedo_report: pedoReportUrl.length > 0 ? {
                    file: pedoReportUrl,
                    tag: "pedo-report",
                } : null,
                area_coords: areaCoordsUrl.length > 0 ? {
                    file: areaCoordsUrl,
                    tag: "area-coords",
                } : null,
                legal_docs: legal_docs,
                area_coordonates: coordonates,
            });
            const user = localStorage.getItem('user');
            if (!user)
                return;
            const userModel = UserModel.fromJson(JSON.parse(user));
            const token = userModel.bearer;
            await axios.post(`${serverUrl}/project`, {
                partner_id: selectedPartnerId,
                partner_name: partners.find((partner) => partner.id == selectedPartnerId)?.name,
                country_id: selectedCountryId,
                country_name: countries.find((country) => country.id == selectedCountryId)?.nicename,
                county_id: selectedCountyId,
                county_name: counties.find((county) => county.id == selectedCountyId)?.name,
                city_id: selectedCityId,
                city_name: cities.find((city) => city.id == selectedCityId)?.name,
                soil_type: soilType,
                type: type,
                co2: co2,
                warning_moisture_level: warningMoistureLevel,
                critical_moisture_level: criticalMoistureLevel,
                social_goal: socialGoal,
                pedo_report: pedoReportUrl.length > 0 ? {
                    file: pedoReportUrl,
                    tag: "pedo-report",
                } : null,
                area_coords: areaCoordsUrl.length > 0 ? {
                    file: areaCoordsUrl,
                    tag: "area-coords",
                } : null,
                legal_docs: legal_docs,
                area_coordonates: coordonates,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Add your token here
                },
            }).then((response) => {
                if (response.status != 201) {
                    setCallState(new ErrorState(response.data.message ?? "An error occurred"));
                    return;
                }
                setCallState(new SuccessState());
                resetForm();
                alert("Project added successfully");
                setIsOpen(false);

                // refresh the projects list
                window.location.reload();
            }).catch((error) => {
                console.error(error);
                setCallState(new ErrorState("An error occurred"));
            });
        }
        else {
            alert("Please fill all the required fields");
        }
    }

    function resetForm() {
        setSelectedPartnerId(null);
        setSelectedCountryId(null);
        setSelectedCountyId(null);
        setSelectedCityId(null);
        setSoilType(null);
        setType(null);
        setCo2(null);
        setSocialGoal(null);
        setPedoReportFile(null);
        setAreaCoordsFile(null);
        setWarningMoistureLevel("30");
        setCriticalMoistureLevel("23");
        setLegalDocs([]);
        setLegalDocsTags([]);
    }

    return (
        <Dialog open={isOpen} >
            <DialogTrigger asChild>
                <Button onClick={() => { setIsOpen(true) }} variant="ghost" className="text-main-green text-xs font-semibold">Add Project</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>Add Project</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {PartnerNameInput()}
                        {CountryInput()}
                        {CountyInput()}
                        {LocationInput()}
                        {SoilTypeInput()}
                        {PedoTypeInput()}
                        {CoordsAreaInput()}
                        {TypeInput()}
                        {Co2QtyInput()}
                        {WarningMoistureLevelInput()}
                        {CriticalMoistureLevelInput()}
                        {SocialGoalInput()}
                        {LegalDocsInput()}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SaveButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function LegalDocsInput() {
        return <div className="flex items-top gap-2 ">
            <Label htmlFor="legal" className="w-[110px] flex-none font-semibold pt-2">
                Legal
            </Label>
            <div className="flex flex-col flex-grow gap-2">
                {
                    legalDocs.map((_, index) => (
                        <div key={index} className="flex items-center gap-2">
                            <Select defaultValue={legalDocsTags[index] ?? ''} onValueChange={(value: string) => {
                                const newTags = [...legalDocsTags];
                                newTags[index] = value;
                                setLegalDocsTags(newTags);
                            }}>
                                <SelectTrigger className="shadow w-[150px]">
                                    <SelectValue id="soil_type" placeholder="Select" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="local-council-decision">Local council decision</SelectItem>
                                    <SelectItem value="mayors-office-agreement">Mayor's office agreement</SelectItem>
                                    <SelectItem value="donation-contract">Donation contract</SelectItem>
                                    <SelectItem value="contract-of-sale">Contract of sale</SelectItem>
                                    <SelectItem value="other">Other</SelectItem>
                                </SelectContent>
                            </Select>
                            {/* <Input id="legal" type="file" className="text-xs py-[2px] px-0 shadow" onChange={(e) => {
                                const file = e.target.files?.[0]; // Obține primul fișier selectat
                                if (!file) return;

                                const reader = new FileReader(); // Inițializează un FileReader

                                reader.onloadend = () => {
                                    const newDocs = [...legalDocs];
                                    newDocs[index] = reader.result as Base64File;
                                    const newFilenames = [...legalDocsFilenames];
                                    newFilenames[index] = file.name;
                                    setLegalDocsFilenames(newFilenames);
                                    setLegalDocs(newDocs);
                                };

                                // Citește fișierul ca și conținut în format Base64
                                reader.readAsDataURL(file);
                            }} /> */}

                            <Input type="text" className="shadow w-40" value={legalDocsFilenames[index]} readOnly placeholder="Filename" />
                            <div>
                                <Input id={`legal_${index}`} type="file" className="hidden" onChange={(e) => {
                                    const file = e.target.files?.[0]; // Obține primul fișier selectat
                                    if (!file) return;

                                    const reader = new FileReader(); // Inițializează un FileReader

                                    reader.onloadend = () => {
                                        const newDocs = [...legalDocs];
                                        newDocs[index] = reader.result as Base64File;
                                        const newFilenames = [...legalDocsFilenames];
                                        newFilenames[index] = file.name;
                                        console.log(index);
                                        console.log(newFilenames);
                                        setLegalDocsFilenames(newFilenames);
                                        setLegalDocs(newDocs);
                                    };

                                    // Citește fișierul ca și conținut în format Base64
                                    reader.readAsDataURL(file);
                                }} />
                                <label htmlFor={`legal_${index}`} className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                                    Browse
                                </label>
                            </div>
                        </div>
                    ))
                }
                <div className="gap-2">
                    <Button variant="ghost" className="text-main-green" onClick={() => {
                        setLegalDocs([...legalDocs, null]);
                        setLegalDocsTags([...legalDocsTags, ""]);
                        setLegalDocsFilenames([...legalDocsFilenames, ""]);
                    }}>
                        Add +
                    </Button>
                </div>

            </div>
        </div>
    }

    function SocialGoalInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="soil_type" className="w-[110px] flex-none font-semibold">
                Social goal*
            </Label>
            <Select defaultValue={socialGoal ?? ''} onValueChange={(value: string) => setSocialGoal(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="soil_type" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="reforestation">Reforestation</SelectItem>
                    <SelectItem value="landslides">Landslides</SelectItem>
                    <SelectItem value="forest-curtain">Forest Curtain</SelectItem>
                    <SelectItem value="forest-park">Forest Park</SelectItem>
                    <SelectItem value="degraded-land">Degraded Land</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                </SelectContent>
            </Select>
        </div>
    }

    function CriticalMoistureLevelInput() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="critical" className="w-[110px] flex-none font-semibold pt-1">
                Critical moisture level %*
            </Label>
            <div className="flex-grow">
                <Input className="shadow" id="critical" min={0} max={100} type="number" placeholder="Type" value={criticalMoistureLevel ?? ""} onChange={(e) => setCriticalMoistureLevel(e.target.value)} />
            </div>
        </div>
    }

    function WarningMoistureLevelInput() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="warning" className="w-[110px] flex-none font-semibold pt-1">
                Warning moisture level %*
            </Label>
            <div className="flex-grow">
                <Input className="shadow" id="warning" type="number" placeholder="Type" value={warningMoistureLevel ?? ""} onChange={(e) => setWarningMoistureLevel(e.target.value)} />
            </div>
        </div>
    }

    function Co2QtyInput() {
        return <div className="flex items-start gap-2">
            <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                Ev. CO2 qty %*
            </Label>
            <div className="flex-grow">
                <Input className="shadow" id="co2" type="text" placeholder="Type" value={co2 ?? ""} onChange={(e) => setCo2(e.target.value)} />
                <div className="text-stone-300 text-[12px] font-medium leading-[10px] pt-2">Evaluator CO2 quantity percentage retained</div>
            </div>
        </div>
    }

    function TypeInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="type" className="w-[110px] flex-none font-semibold">
                Type*
            </Label>
            <div className="w-[150px]">
                <Select defaultValue={type ?? ''} onValueChange={(value: string) => setType(value)}>
                    <SelectTrigger className="shadow">
                        <SelectValue id="type" placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="plain">Plain</SelectItem>
                        <SelectItem value="hill">Hill</SelectItem>
                        <SelectItem value="mountain">Mountain</SelectItem>
                    </SelectContent>
                </Select>
            </div>
        </div>
    }

    function CoordsAreaInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="area_cords" className="w-[110px] flex-none font-semibold">
                GPS area coords*
            </Label>
            {/* <Input id="area_cords" type="file" className="text-xs py-[2px] px-0 shadow" accept=".kml" onChange={handleAreaCoordsFileChange} /> */}
            <Input id="area_cords_filename" type="text" className="shadow w-40" value={areaCoordsFilename} readOnly placeholder="Filename" />
            <div>
                <Input id="area_cords" type="file" multiple className="hidden" accept=".kml" onChange={handleAreaCoordsFileChange} />
                <label htmlFor="area_cords" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                    Browse
                </label>
            </div>
        </div>
    }

    function PedoTypeInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="pedo_report" className="w-[110px] flex-none font-semibold">
                Upload pedo report
            </Label>
            {/* <Input id="pedo_report" type="file" className="text-xs py-[2px] px-0 shadow" onChange={handlePedoReportFileChange} /> */}
            <Input id="pedo_report_filename" type="text" className="shadow w-40" value={pedoReportFilename} readOnly placeholder="Filename" />
            <div>
                <Input id="pedo_report" type="file" multiple className="hidden" onChange={handlePedoReportFileChange} />
                <label htmlFor="pedo_report" className="text-m py-[2px] px-3 text-main-green rounded-md cursor-pointer ">
                    Browse
                </label>
            </div>
            {/* <label htmlFor="pedo_report" className="text-main-green text-[12px] font-medium leading-[10px] pt-2">Browse</label> */}
        </div>
    }

    function SoilTypeInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="soil_type" className="w-[110px] flex-none font-semibold">
                Soil type*
            </Label>
            <Select defaultValue={soilType ?? ''} onValueChange={(value: string) => setSoilType(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="soil_type" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="forestry-land">Forestry Land</SelectItem>
                </SelectContent>
            </Select>
        </div>
    }

    function LocationInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="location" className="w-[110px] flex-none font-semibold">
                Location*
            </Label>
            <Select defaultValue={selectedCityId ?? ''} onValueChange={(value: string) => setSelectedCityId(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="location" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    {cities.map((city) => (
                        <SelectItem key={city.id} value={city.id}>{city.name}</SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    }

    function CountyInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="county" className="w-[110px] flex-none font-semibold">
                County*
            </Label>
            <Select defaultValue={selectedCountyId ?? ''} onValueChange={(value: string) => setSelectedCountyId(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="county" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    {counties.map((county) => (
                        <SelectItem key={county.id} value={county.id}>{county.name}</SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    }

    function CountryInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="country" className="w-[110px] flex-none font-semibold">
                Country*
            </Label>
            <Select defaultValue={selectedCountryId ?? ''} onValueChange={(value: string) => setSelectedCountryId(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="country" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    {countries.map((country) => (
                        <SelectItem key={country.id} value={country.id}>{country.nicename}</SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    }

    function PartnerNameInput() {
        return <div className="flex items-center gap-2">
            <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold">
                Partener name*
            </Label>
            <Select defaultValue={selectedPartnerId ?? ''} onValueChange={(value: string) => setSelectedPartnerId(value)}>
                <SelectTrigger className="shadow">
                    <SelectValue id="partner_name" placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    {Partner.sortByName(partners).map((partner) => (
                        <SelectItem key={partner.id} value={partner.id}>{partner.name}</SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    }

    function SaveButton() {
        return (
            <>
                {
                    callState instanceof ErrorState &&
                    <div className="text-red-500">{callState.errorMessage}</div>
                }
                {/* if call state is loadingstate then show a circular indicator, else show save button */}
                {
                    callState instanceof LoadingState ?
                        <Button className="w-[100px] h-[40px] flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-25"
                                    fill="currentColor"
                                    d="M12 6a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
                                ></path>
                            </svg>
                            Saving
                        </Button>
                        :
                        <Button type="submit" className="w-[144px]" onClick={handleSubmit}>Save</Button>
                }
            </>
        )
    }
}
