import {
    DotsVerticalIcon,
} from "@radix-ui/react-icons"
import {
    ColumnDef,
    ColumnFiltersState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { useMemo, useRef, useState } from "react"
import Action from "@/pages/home/models/actions"
import TreeHoleDetection from "../models/tree-hole-detection-model"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Share from "yet-another-react-lightbox/plugins/share";
import "yet-another-react-lightbox/styles.css";

import { serverUrl } from "@/config/config"
export function ProjectDetections(
    {
        detections,
    }: {
        detections: TreeHoleDetection[]
    }
) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [open, setOpen] = useState(false);
    const captionsRef = useRef(null);

    const zoomRef = useRef(null);
    const data = useMemo(() => detections, [detections]);
    const [imageToOpen, setImageToOpen] = useState<string | null>(null);


    const columns = useMemo<
        ColumnDef<TreeHoleDetection>[]
    >(
        () => [
            {
                accessorKey: "date",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Date
                            </Button>
                        </div>
                    )
                },
                cell: ({ row }) => {

                    return (
                        <div className="flex flex-col items-center">
                            <div className="text-xs text-black font-bold">{row.original.date.split("T")[0]}</div>
                        </div>
                    );

                },
            },
            {
                accessorKey: "tree_number",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Number of  <br />trees
                            </Button>

                        </div>
                    )
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <div className="text-xs text-black font-bold">{row.original.tree_number}</div>
                        </div>
                    );
                },
            },
            {
                accessorKey: "tree_hole_number",
                header: ({ }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Number of  <br />tree holes
                            </Button>

                        </div>

                    )
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <div className="text-xs text-black font-bold">{row.original.tree_hole_number}</div>
                        </div>
                    );
                },
            },
            {
                accessorKey: "description",
                header: ({ }) => {

                    return (
                        <div className="flex flex-col items-center">
                            <Button
                                variant="ghost"
                                className="font-bold text-xs text-black"
                            >
                                Description
                            </Button>
                        </div>

                    )
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex flex-col items-center">
                            <div className="text-xs text-black font-bold">{row.original.description}</div>
                        </div>
                    );
                },
            },
            {
                id: "actions",
                header: ({ }) => {

                },
                cell: ({ row }) => {
                    if (row.original instanceof Action) {

                        return (
                            // center on the same line
                            <div className="items-center flex" >
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="ghost" className="h-8 w-8 p-0">
                                            <span className="sr-only">Open menu</span>
                                            <DotsVerticalIcon className="h-4 w-4 text-main-green" />
                                        </Button>
                                    </DropdownMenuTrigger>

                                </DropdownMenu>

                            </div>
                        )
                    } else {
                        return (
                            <div className="items-center flex" >
                                {/*  view button */}
                                <Button onClick={() => {
                                    setImageToOpen(row.original.image_filename);
                                    setOpen(true);
                                }} variant="ghost" className="text-main-green text-xs font-semibold">View</Button>
                            </div>

                        );
                    }

                },
            },
        ], []
    )




    const table = useReactTable({
        data,
        columns,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getExpandedRowModel: getExpandedRowModel(),
        state: {
            columnFilters,
            columnVisibility,

        },
    })

    return (
        <div>
            <div className="w-full bg-white rounded-[10px] bg-opacity-10 shadow border border-black border-opacity-10 pl-4 pr-4 text-center">
                <div className="flex items-center py-4">
                    <div className="text-l font-bold text-black ">Project detections</div>
                </div>
                <div className="rounded-md border mb-4">
                    <Table>
                        <TableHeader >
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableHead
                                                className="text-center"
                                                key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        className="hover:bg-[#A6CE39]/10"
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow className="hover:bg-[#A6CE39]/10">
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div className="flex items-center justify-end space-x-2 py-4">
                    <div className="space-x-2">
                        <Button
                            variant="outline"
                            className="bg-main-green text-white"
                            size="sm"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            <FaChevronLeft />
                        </Button>


                        <Button
                            variant="outline"
                            className="bg-main-green text-white"
                            size="sm"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            <FaChevronRight />
                        </Button>
                    </div>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                plugins={[Zoom, Captions, Download, Share]}
                zoom={{ ref: zoomRef }}
                captions={{ ref: captionsRef }}
                slides={[
                    {
                        src: `${serverUrl}${imageToOpen}`,
                        title: "First Image",
                        description: "First Image description",
                    },

                ]}
            />
        </div>
    )
}
