import RolesModel from "./roles";

class UserModel {
    id: string;
    email: string;
    name: string;
    cover: string;
    uId: string;
    bearer: string;
    roles: RolesModel[];

    constructor(
        id: string,
        email: string,
        name: string,
        cover: string,
        uId: string,
        bearer: string,
        roles: RolesModel[]
    ) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.cover = cover;
        this.uId = uId;
        this.bearer = bearer;
        this.roles = roles;
    }

    // from json
    static fromJson(json: any): UserModel {
        return new UserModel(
            json.id,
            json.email,
            json.name,
            json.cover,
            json.uId,
            json.bearer,
            json.roles.map((role: any) => RolesModel.fromJson(role))
        );
    }

    // to json
    toJson(): any {
        return {
            id: this.id,
            email: this.email,
            name: this.name,
            cover: this.cover,
            uId: this.uId,
            bearer: this.bearer,
            roles: this.roles.map((role) => role.toJson())
        }
    }
}

export default UserModel;