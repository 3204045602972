import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Button } from '@/components/ui/button';
import { crmUrl } from '@/config/config';
import UserModel from '@/models/user';


const LoginView: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!email || !password) {
            setError('Please enter both email and password.');
            return;
        }

        setError(null);
        onLogin(email, password);
    };

    const onLogin = async (email: string, password: string) => {

        // /login with email and pass as formdata input
        const formData = new FormData();
        formData.append('user', email);
        formData.append('pass', password);

        setLoading(true);

        const response = await fetch(`${crmUrl}/login`, {
            method: 'POST',
            body: formData
        });

        setLoading(false);

        if (response.ok) {
            const data = await response.json();
            try {
                const user = UserModel.fromJson(data[0]);
                localStorage.setItem('user', JSON.stringify(user));
                // redirect to /
                window.location.href = '/';
            } catch (e) {
                setError('Invalid email or password.');
            }
        } else {
            console.log(response);
            setError('Invalid email or password.');
        }
    }

    return (
        <div className="flex items-center justify-center min-h-screen ">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md border border-gray-200 p-6 sm:p-8 lg:p-10">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">Login</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-main-green focus:border-main-green"
                        />
                    </div>
                    <div>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-main-green focus:border-main-green"
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <Button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-main-green text-white py-2 rounded-md hover:bg-main-green-dark focus:outline-none focus:ring-2 focus:ring-main-green focus:ring-opacity-50"
                    >
                        Login
                    </Button>
                </form>
                <p className="mt-4 text-sm text-gray-600">
                    Don't have an account? <a href="" className="text-main-green hover:underline">Sign up</a>
                </p>
            </div>
        </div>
    );
};

export default LoginView;