import Humidity from "@/pages/project-view/models/humidity-model"
import Action from "./actions"
import AuditAction from "./audit-action"
import FileModel from "./file"
import ProjectZone from "./project_zone"




class Project {
    project_id: number
    project_name: string
    partner_name: string
    partner_id: number
    country_id: number
    country_name: string
    county_id: number | null
    county_name: string | null
    city_id: number | null
    city_name: string | null
    soil_type: string
    type: string
    co2: string
    warning_moisture_level: string
    critical_moisture_level: string
    social_goal: string
    pedo_report: string | null
    area_coords: string | null
    legal_docs: FileModel[]
    area_coodonates: string[]
    created_at: string
    updated_at: string
    zones: ProjectZone[]
    actions: Action[]
    audit_actions: AuditAction[]
    humidities: Humidity[]


    constructor(
        project_id: number,
        project_name: string,
        partner_name: string,
        partner_id: number,
        country_id: number,
        country_name: string,
        county_id: number | null,
        county_name: string | null,
        city_id: number | null,
        city_name: string | null,
        soil_type: string,
        type: string,
        co2: string,
        warning_moisture_level: string,
        critical_moisture_level: string,
        social_goal: string,
        pedo_report: string | null,
        area_coords: string | null,
        legal_docs: FileModel[],
        area_coodonates: string[],
        created_at: string,
        updated_at: string,
        zones: ProjectZone[],
        action: Action[],
        audit_actions: AuditAction[],
        humidities: Humidity[] = []
    ) {
        this.project_id = project_id
        this.project_name = project_name
        this.partner_name = partner_name
        this.partner_id = partner_id
        this.country_id = country_id
        this.country_name = country_name
        this.county_id = county_id
        this.county_name = county_name
        this.city_id = city_id
        this.city_name = city_name
        this.soil_type = soil_type
        this.type = type
        this.co2 = co2
        this.warning_moisture_level = warning_moisture_level
        this.critical_moisture_level = critical_moisture_level
        this.social_goal = social_goal
        this.pedo_report = pedo_report
        this.area_coords = area_coords
        this.legal_docs = legal_docs
        this.area_coodonates = area_coodonates
        this.created_at = created_at
        this.updated_at = updated_at
        this.zones = zones
        this.actions = action
        this.audit_actions = audit_actions
        this.humidities = humidities
    }


    // from json
    static fromJson(data: any) {
        return new Project(
            data.project_id,
            data.project_name,
            data.partner_name,
            data.partner_id,
            data.country_id,
            data.country_name,
            data.county_id,
            data.county_name,
            data.city_id,
            data.city_name,
            data.soil_type,
            data.type,
            data.co2,
            data.warning_moisture_level,
            data.critical_moisture_level,
            data.social_goal,
            data.pedo_report,
            data.area_coords,
            FileModel.fromJsonArray(data.legal_docs),
            data.area_coordonates,
            data.created_at,
            data.updated_at,
            ProjectZone.fromJsonArray(data.zones),
            Action.fromJsonArray(data.actions ?? []),
            AuditAction.fromJsonArray(data.audit_actions ?? []),
            Humidity.fromJsonArray(data.humidities ?? [])
        )
    }
}
export default Project