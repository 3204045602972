import { Button } from '@/components/ui/button';
import { useState, useEffect } from 'react';

function LogoutButton() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (isDialogOpen) {
            document.body.style.overflow = 'hidden'; // Blochează scroll-ul
        } else {
            document.body.style.overflow = 'auto'; // Reenable scroll-ul
        }
        return () => {
            document.body.style.overflow = 'auto'; // Asigură-te că scroll-ul este reactivat la demontare
        };
    }, [isDialogOpen]);

    const handleLogout = () => {
        // remove user from local storage
        localStorage.removeItem('user');
        // reload the page
        window.location.href = '/';
        setIsDialogOpen(false);
    };

    return (
        <div>
            {/* Butonul de logout */}
            <Button
                variant="ghost"
                className="text-main-green p-2 font-bold hover:bg-main-green-dark transition"
                onClick={() => setIsDialogOpen(true)}
            >
                Logout
            </Button>

            {/* Dialogul de confirmare */}
            {isDialogOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={() => setIsDialogOpen(false)}>
                    <div
                        className="bg-white rounded-lg p-6 w-80 shadow-lg"
                        onClick={(e) => e.stopPropagation()} // Previne închiderea la click pe dialog
                    >
                        <h2 className="text-lg font-bold mb-4">Confirm Logout</h2>
                        <p className="mb-6">Are you sure you want to logout?</p>
                        <div className="flex justify-end gap-4">
                            <button
                                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-main-green text-white rounded hover:bg-main-green-dark"
                                onClick={handleLogout}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LogoutButton;