import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

import { Label } from "@/components/ui/label"

import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect, useState } from "react"
import { CallState, ErrorState, InitialState, LoadingState } from "@/models/callstate"
import axios from "axios"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { MapZoneEditor } from "../map-zone-editor"
import { Checkbox } from "@/components/ui/checkbox"
import ProjectZone from "../../models/project_zone"
import Species from "../../models/species"
import Project from "../../models/project"
import { serverUrl } from "@/config/config"
import UserModel from "@/models/user"
// import Species from "../models/species"


export function EditPartnerZone(
    { project_zone }: { project_zone: ProjectZone }
) {
    const [isOpen, setIsOpen] = useState(false);
    const [callState, setCallState] = useState<CallState>(new InitialState());

    // project
    const [project, setProject] = useState<Project | null>(null);

    // selected values
    const [co2, setCo2] = useState<string | null>(project_zone.co2);
    const [species, setSpecies] = useState<Species[]>(project_zone.species);
    const [schema, setSchema] = useState<string | null>(project_zone.field_schema);
    const [model, setModel] = useState<string | null>(project_zone.model);
    const [selectedZone, setSelectedZone] = useState<number[][]>(converStringCoordinatesToArray(project_zone.area_coodonates));

    function converStringCoordinatesToArray(coordinates: string): number[][] {
        const coordinatesArray = coordinates.split(' ').map((coordinate) => {
            const [latitude, longitude] = coordinate.split(',');
            return [parseFloat(latitude), parseFloat(longitude)];
        });
        return coordinatesArray;
    }

    function convertZoneCoordinatesStringToArray(zoneCoordinates: string): number[][] {
        return zoneCoordinates.split(' ').map((coordinate) => {
            const [latitude, longitude] = coordinate.split(',');
            return [parseFloat(longitude), parseFloat(latitude)];
        });
    }

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user)
            return;
        const userModel = UserModel.fromJson(JSON.parse(user));
        const token = userModel.bearer;
        axios.get(`${serverUrl}/project?zone_id=` + project_zone.zone_id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            setProject(Project.fromJson(response.data[0]));
        }).catch((error) => {
            setCallState(new ErrorState(error.message));
        }
        );
    }, [project_zone.zone_id]);

    async function handleSubmit() {
        if (selectedZone.length === 0) {
            alert("Please select a zone");
            return;
        }
        if (co2 === null) {
            alert("Please enter the CO2 quantity");
            return;
        }
        if (schema === null) {
            alert("Please select a schema");
            return;
        }
        if (model === null) {
            alert("Please select a model");
            return;
        }
        if (species.length === 0) {
            alert("Please select at least one specie");
            return;
        }
        setCallState(new LoadingState());
        axios.put(`${serverUrl}/project/zone/` + project_zone.zone_id, {
            co2: co2,
            species: species.map((specie) => ({ species_slug: specie.id, sapling_number: specie.number, species_name: specie.name })),
            field_schema: schema,
            model: model,
            zone_coordonates: selectedZone.map((coordinate) => coordinate.join(',')).join(' '),
        }).then((response) => {
            setCallState(new InitialState());
            alert(response.data.message);
            setIsOpen(false);
        }).catch((error) => {
            setCallState(new ErrorState(error.message));
        });
    }

    const poligons = project?.area_coodonates ? project?.area_coodonates.map((zone) => converStringCoordinatesToArray(zone)) : [];

    return (
        <Dialog open={isOpen}>
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    Edit
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle>Edit Partner zone</DialogTitle>
                    <div onClick={() => { setIsOpen(false) }} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <ScrollArea className="max-h-[70vh]">
                    <div className="grid gap-4 py-4">
                        {PartnerNameInput()}
                        {Co2Input()}
                        {SpeciesInput()}
                        {SchemaInput()}
                        {ModelInput()}
                        <MapZoneEditor
                            selectedZone={selectedZone}
                            onSave={(zone) => {
                                setSelectedZone(zone);
                            }}
                            zones={[
                                ...poligons,
                                ...project?.zones.filter((zone) => zone.zone_id !== project_zone.zone_id).map((zone) => convertZoneCoordinatesStringToArray(zone.area_coodonates)) ?? [],
                            ]}
                            numberOfPolygons={poligons.length}
                        />
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <SavePartnerButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

    function ModelInput() {
        return (
            <div className="flex items-start gap-2">
                <Label htmlFor="schema" className="w-[110px] flex-none font-semibold pt-2">
                    Model*
                </Label>
                {/* 2 checkboxes, one with interleaved text and one with random*/}
                <div className="pt-2 flex items-center space-x-2">
                    <Checkbox
                        id="interleaved"
                        onClick={
                            () => {
                                setModel("interleaved");
                            }
                        }
                        checked={model === "interleaved"}
                    />
                    <label
                        htmlFor="interleaved"
                        className="text-stone-500 text-[14px] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        interleaved
                    </label>
                </div>
                <div className="pl-10 pt-2 flex items-center space-x-2">
                    <Checkbox
                        id="random"
                        onClick={
                            () => {
                                setModel("random");
                            }
                        }
                        checked={model === "random"}
                    />
                    <label
                        htmlFor="random"
                        className="text-stone-500 text-[14px] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 "
                    >
                        random
                    </label>
                </div>
            </div>
        );
    }

    function SchemaInput() {
        return (
            <div className="flex items-start gap-2">
                <Label htmlFor="schema" className="w-[110px] flex-none font-semibold pt-2">
                    Schema*
                </Label>
                <div className="flex flex-col flex-grow gap-2">
                    <div className="flex items-center gap-2">
                        <Select defaultValue={schema ?? ''} onValueChange={(value: string) => {
                            setSchema(value);
                        }}>
                            <SelectTrigger className="shadow">
                                <SelectValue id="soil_type" placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="1-1">1:1</SelectItem>
                                <SelectItem value="2-1">2:1</SelectItem>
                                <SelectItem value="3-4">3:4</SelectItem>
                                <SelectItem value="4-4">4:4</SelectItem>
                                <SelectItem value="miyawaki">Miyawaki</SelectItem>
                                <SelectItem value="street-alignament">Street Alignament</SelectItem>
                                <SelectItem value="other">Other</SelectItem>
                            </SelectContent>
                        </Select>

                    </div>
                </div>
            </div>
        )
    }

    function PartnerNameInput() {
        return (
            <div className="flex items-center gap-2">
                <Label htmlFor="partner_name" className="w-[110px] flex-none font-semibold" >
                    Partener
                </Label>
                <Select value={project_zone.partner_name} >
                    <SelectTrigger className="shadow">
                        <SelectValue id="partner_name" placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value={project_zone.partner_name}>{project_zone.partner_name}</SelectItem>
                    </SelectContent>
                </Select>
            </div>
        )
    }

    function Co2Input() {
        return (
            <div className="flex items-start gap-2">
                <Label htmlFor="co2" className="w-[110px] flex-none font-semibold pt-1">
                    CO2 qty %*
                </Label>
                <div className="flex-grow">
                    <Input
                        id="co2"
                        type="text"
                        className="shadow"
                        placeholder="CO2 qty %"
                        value={co2 ?? ''}
                        onChange={(e) => setCo2(e.target.value)}
                    />
                    <div className="text-stone-300 text-[12px] font-medium leading-[10px] pt-2">
                        Partner CO2 quantity percentage earned
                    </div>
                </div>
            </div>
        )
    }

    function SpeciesInput() {
        return <div className="flex items-top gap-2 ">
            <Label htmlFor="species" className="w-[110px] flex-none font-semibold pt-2">
                Species*
            </Label>
            <div>
                <div className="flex flex-col flex-grow gap-2">
                    {
                        species.map((_, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <Select defaultValue={species[index].id ?? ''} onValueChange={(value: string) => {
                                    const newSpecies = [...species];
                                    newSpecies[index] = new Species(value, buildSpeciesName(value), species[index].number);
                                    setSpecies(newSpecies);
                                }}>
                                    <SelectTrigger className="shadow">
                                        <SelectValue id="species" placeholder="Select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="poplar">Poplar</SelectItem>
                                        <SelectItem value="oak">Oak</SelectItem>
                                        <SelectItem value="wax-cherry">Wax Cherry</SelectItem>
                                        <SelectItem value="fir">Fir</SelectItem>
                                        <SelectItem value="spruce">Spruce</SelectItem>
                                        <SelectItem value="chestnut">Chestnut</SelectItem>
                                        <SelectItem value="pine">Pine</SelectItem>
                                        <SelectItem value="ash">Ash</SelectItem>
                                        <SelectItem value="acacia">Acacia</SelectItem>
                                        <SelectItem value="willow-tree">Willow Tree</SelectItem>
                                        <SelectItem value="mountain-ash">Mountain Ash</SelectItem>
                                        <SelectItem value="glade">Glade</SelectItem>
                                        <SelectItem value="elm">Elm</SelectItem>
                                        <SelectItem value="maple">Maple</SelectItem>
                                        <SelectItem value="other">Other</SelectItem>
                                    </SelectContent>
                                </Select>
                                <Input key={index} className="shadow" id="specie-number" type="text" placeholder="Number" value={species[index].number} onChange={(e) => {
                                    const newSpecies = [...species];
                                    newSpecies[index] = new Species(species[index].id, species[index].name, parseInt(e.target.value));
                                    setSpecies(newSpecies);
                                }} />
                                {
                                    index > 0 ?
                                        <Button variant="ghost" className="text-red-500" onClick={() => {
                                            setSpecies(species.filter((_, i) => i !== index));
                                        }}>
                                            Remove
                                        </Button> : null
                                }
                                {
                                    index === species.length - 1 ?
                                        <Button variant="ghost" className="text-main-green" onClick={() => {
                                            setSpecies([...species, Species.empty()]);
                                        }}>
                                            Add +
                                        </Button> : null
                                }

                            </div>
                        ))
                    }<div className="text-stone-300 text-m font-medium leading-none">
                        Total: {' '}
                        {
                            species.map((_, index) => (
                                species[index].number
                            )).reduce((acc, val) => acc + val, 0)
                        }
                        {' '}saplings</div>
                </div>
            </div>
        </div>
    }

    function buildSpeciesName(species_id: string) {
        switch (species_id) {
            case "specie-1":
                return "Specie 1";
            case "specie-2":
                return "Specie 2";
            case "specie-3":
                return "Specie 3";
            case "specie-4":
                return "Specie 4";
            case "other":
                return "Other";
            default:
                return "";
        }
    }
    function SavePartnerButton() {
        return (
            <>
                {
                    callState instanceof ErrorState &&
                    <div className="text-red-500">{callState.errorMessage}</div>
                }
                {
                    callState instanceof LoadingState ?
                        <Button className="w-[100px] h-[40px] flex items-center justify-center">
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-25"
                                    fill="currentColor"
                                    d="M12 6a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1z"
                                ></path>
                            </svg>
                            Saving
                        </Button>
                        :
                        <Button type="submit" className="w-[144px]" onClick={handleSubmit}>Save</Button>
                }
            </>
        );
    }
}
