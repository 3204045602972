import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { serverUrl } from "@/config/config";
import Project from "@/pages/home/models/project";
import { useEffect, useState } from "react";
import TreeHoleDetection from "../../models/tree-hole-detection-model";
import axios from "axios";
import UserModel from "@/models/user";

export function ViewTreeHoleDetection({ project }: { project: Project }) {
    const [isOpen, setIsOpen] = useState(false);
    const [treeHoleDetections, setTreeHoleDetections] = useState<TreeHoleDetection[]>([]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level

    // Fetch data
    useEffect(() => {
        const fetchTreeHoleDetections = async () => {
            try {
                const user = localStorage.getItem("user");
                if (!user) return;
                const userModel = UserModel.fromJson(JSON.parse(user));
                const token = userModel.bearer;
                const response = await axios.get(
                    `${serverUrl}/project/${project.project_id}/tree-hole-detection`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setTreeHoleDetections(TreeHoleDetection.fromJsonArray(response.data));
            } catch (error) {
                console.error("Error fetching tree hole detections:", error);
            }
        };
        fetchTreeHoleDetections();
    }, [project.project_id]);

    function handleClose() {
        setIsOpen(false);
        setSelectedImage(null); // Reset image when closing the dialog
        setZoomLevel(1); // Reset zoom level
    }

    const handleDetailsClick = (imageFilename: string) => {
        setSelectedImage(`${serverUrl}${imageFilename}`); // Modify the path based on your server's file structure
    };

    const handleZoomIn = () => {
        setZoomLevel(prevZoom => Math.min(prevZoom * 1.1, 3)); // Zoom in, with max zoom level of 3x
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => Math.max(prevZoom / 1.1, 1)); // Zoom out, with min zoom level of 1x
    };

    return (
        <Dialog open={isOpen}>
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant="ghost"
                    className="text-main-green relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                    View detections
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[900px] overflow-hidden">
                <DialogHeader className="flex flex-row justify-between items-center">
                    <DialogTitle>View Tree Hole Detection</DialogTitle>
                    <div onClick={() => setIsOpen(false)} className="cursor-pointer">
                        <span className="text-gray-500 text-xs">Close</span>
                        <span className="text-main-green pl-2">[x]</span>
                    </div>
                </DialogHeader>
                <div className="flex space-x-4">
                    <ScrollArea className="max-h-[70vh] overflow-y-auto w-1/5">
                        <div className="grid gap-4 py-4">
                            {treeHoleDetections.map((detection, index) => (
                                <div
                                    key={index}
                                    className="bg-white shadow-md rounded-md p-4 border border-gray-200">
                                    <h3 className="text-l font-semibold mb-2">
                                        {detection.date.split("T")[0]}
                                    </h3>
                                    {/* number of trees */}
                                    <p className="text-sm text-gray-600">
                                        {detection.tree_number} trees
                                    </p>

                                    {/* number of holes */}
                                    <p className="text-sm text-gray-600">
                                        {detection.tree_hole_number} tree holes
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        {detection.description || "No description available"}
                                    </p>
                                    <div className="mt-4">
                                        <Button
                                            variant="outline"
                                            className="w-full"
                                            onClick={() => handleDetailsClick(detection.image_filename)}>
                                            Details
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </ScrollArea>

                    {/* Show image if selected */}
                    {selectedImage && (
                        <div className="w-4/5 flex flex-col items-center">
                            <div className="p-4 border border-gray-300 rounded-md bg-gray-50 w-full">
                                <h4 className="font-semibold">Image</h4>
                                <div className="relative">
                                    <img
                                        src={selectedImage}
                                        alt="Tree Hole Image"
                                        className="mt-2 w-full object-contain"
                                        style={{
                                            transform: `scale(${zoomLevel})`, // Apply zoom scale
                                            transition: "transform 0.3s ease-in-out", // Smooth transition for zoom
                                        }}
                                    />
                                </div>
                                <div className="flex justify-between mt-2">
                                    <Button variant="outline" onClick={handleZoomOut} className="w-1/3">
                                        Zoom Out
                                    </Button>
                                    <Button variant="outline" onClick={handleZoomIn} className="w-1/3">
                                        Zoom In
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <DialogFooter>
                    <CloseButton />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );

    function CloseButton() {
        return (
            <Button type="submit" className="w-[144px]" onClick={handleClose}>
                Close
            </Button>
        );
    }
}
