
class TreeHoleDetection {
    id: number;
    project_id: number;
    image_filename: string;
    csv_filename: string;
    tree_number: number;
    tree_hole_number: number;
    description: string;
    date: string;

    constructor(
        id: number,
        project_id: number,
        image_filename: string,
        csv_filename: string,
        tree_number: number,
        tree_hole_number: number,
        description: string,
        date: string
    ) {
        this.id = id;
        this.project_id = project_id;
        this.image_filename = image_filename;
        this.csv_filename = csv_filename;
        this.tree_number = tree_number;
        this.tree_hole_number = tree_hole_number;
        this.description = description;
        this.date = date;
    }


    static fromJson(json: any): TreeHoleDetection {
        return new TreeHoleDetection(
            json.id,
            json.project_id,
            json.image_filename,
            json.csv_filename,
            json.tree_number,
            json.tree_hole_number,
            json.description,
            json.date
        );
    }

    static fromJsonArray(json: any): TreeHoleDetection[] {
        return json.map((item: any) => TreeHoleDetection.fromJson(item));
    }


}

export default TreeHoleDetection;
